import { template as template_c2a6ee7c07a34b7ea9de398a5cf3b00f } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import PluginOutlet from "discourse/components/plugin-outlet";
const CategoryTitleBefore = template_c2a6ee7c07a34b7ea9de398a5cf3b00f(`
  <PluginOutlet
    @name="category-title-before"
    @outletArgs={{hash category=@category}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CategoryTitleBefore;
