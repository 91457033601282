import { template as template_2ba67e399cb34ad4ae08e2bc5318e0bc } from "@ember/template-compiler";
const SidebarSectionMessage = template_2ba67e399cb34ad4ae08e2bc5318e0bc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
